import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";

export default function Help() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8  text-right">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-2xl font-semibold leading-7 text-indigo-600">
          طريقة اللعب؟
        </p>

        <p className="mt-6 text-xl ">
          هناك ثلاث أحجام مختلفة عند الضغط على النّرد سيظهر لك صغير أو وسط أو
          كبير تستطيع وضع الكبير (اكس) على سبيل المثال على (او) الوسط أو الصغير
          وكذلك تستطيع وضع الوسط (اكس) على (او) الصغير وليس العكس تستطيع إعادة
          المحاولة بالضغط على الزر الموجود بجوار النّرد
        </p>
        <p className="text-2xl font-semibold leading-7 text-indigo-600 mt-10">
          إذا واجهتك مشكلة؟
        </p>
        <p className="mt-6 text-xl ">
          اضغط على إعادة تشغيل اللعبة من الصفحة الرئيسية أو من الإعدادات حتى
          تحصل على آخر تحديث
        </p>
        <div className="flex flex-row  justify-end gap-5 pt-20 text-right items-center">
          <p>
            <a
              className="text-indigo-600 underline  text-[10px] sm:text-base"
              href="mailto:xo-pro@naem.me"
            >
              {" "}
              xo-pro@naem.me{" "}
            </a>{" "}
          </p>
          <p className=" text-[10px]  sm:text-base">
            إذا لديك أي اقتراح أو واجهتك أي مشكلة تواصل معنا
          </p>
        </div>
      </div>
    </div>
  );
}
