import { useParams } from "react-router-dom";
import { Loading } from "./components/Loading";
import { useEffect, useState } from "react";
import ReactStoreBadges from "react-store-badges";
import { LoadingIcon } from "./logo";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function Accept() {
  const { roomId } = useParams();
  const [room, setRoom] = useState(roomId);

  useEffect(() => {
    const fetchRoom = async () => {
      window.location.replace(`xo-pro:///(tabs)/home/accept/${roomId}`);
      setTimeout(() => {
        window.location.href = `/`;
      }, 10000);
    };
    fetchRoom();
  }, [roomId]);

  if (room != null) {
    return (
      <main className="grid min-h-full place-items-center bg-white px-6 py-64 sm:py-64 lg:px-8">
        <div className="text-center">
          <Loading size={70} />
        </div>
      </main>
    );
  } else {
    return (
      <>
        {/*
            This example requires updating your template:
    
            ```
            <html class="h-full bg-white">
            <body class="h-full">
            ```
          */}
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-indigo-600">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Page not found
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <br></br>
            <p className="mt-6 text-base leading-7 text-gray-600">
              if you don't have app XO Pro installed, please go to the app store
              and download it now and try again.
            </p>
            {/* <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Install XO Pro
              </a>
              <a href="#" className="text-sm font-semibold text-gray-900">
                Contact support <span aria-hidden="true">&rarr;</span>
              </a>
            </div> */}

            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://apps.apple.com/us/app/id6503325138"
                className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
              >
                <LoadingIcon />
              </a>
              <ReactStoreBadges
                platform={"android"}
                url={
                  "https://play.google.com/store/apps/details?id=com.adeb1991.xo"
                }
                locale={"en-us"}
              />
            </div>
          </div>
        </main>
      </>
    );
  }
}
