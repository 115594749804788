import { useEffect } from "react";

export const Privacy = () => {
  useEffect(() => {
    document.title = "Privacy";
  }, []);

  return (
    <div className="container mx-auto max-w-screen-lg px-4 md:px-8 mt-32">
      <div class="container mx-auto my-16 max-w-screen-lg px-6 md:my-24 md:px-8">
        <h2 className="text-center text-1xl font-semibold md:text-4xl">
          XO Pro Privacy Policy
        </h2>
      </div>
      <hr />
      <article class="my-5 md:my-24">
        <p>
          Ahmad Alsobhi operates the website "XO Pro" at xo-pro-maem.me. I take
          your privacy seriously. To better protect your privacy, I provide this
          privacy policy notice explaining the way your personal information is
          collected and used.
        </p>
        <p>
          <strong>Collection of Routine Information</strong>
        </p>
        <p>
          This website track basic information about its visitors. This
          information includes, but is not limited to, IP addresses, browser
          details, timestamps and referring pages. None of this information can
          personally identify specific visitors to this website. The information
          is tracked for routine administration and maintenance purposes.
        </p>
        <p>
          <strong>Cookies</strong>
        </p>
        <p>
          Where necessary, this website uses cookies to store information about
          a visitor’s preferences and history to better serve the visitor and/or
          present the visitor with customized content.
        </p>
        <p>
          <strong>Advertisement and Other Third Parties</strong>
        </p>
        <p>
          Advertising partners and other third parties may use cookies, scripts
          and/or web beacons to track visitor activities on this website to
          display advertisements and other useful information. Such tracking is
          done directly by the third parties through their servers and is
          subject to their privacy policies. This website has no access or
          control over these cookies, scripts and/or web beacons that may be
          used by third parties.
        </p>
        <div>
          <p>
            I have included links on this website for your use and reference. I
            am not responsible for the privacy policies on these websites. You
            should be aware that the privacy policies of these websites may
            differ from my own.
          </p>
          <p>
            Link to the privacy policy of third-party service providers used by
            the website
          </p>
          <ul>
            <li>
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/policies/analytics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics for Firebase
              </a>
            </li>
            {/* <!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----> */}
          </ul>
        </div>
        <p>
          <strong>Security</strong>
        </p>
        <p>
          The security of your personal information is important to me, but
          remember that no method of transmission over the Internet, or method
          of electronic storage, is 100% secure. While I strive to use
          commercially acceptable means to protect your personal information, I
          cannot guarantee its absolute security.
        </p>
        <p>
          <strong>Changes To This Privacy Policy</strong>
        </p>
        <p>
          This Privacy Policy is effective as of 2024-04-19 and will remain in
          effect except concerning any changes in its provisions in the future,
          which will be in effect immediately after being posted on this page. I
          reserve the right to update or change my Privacy Policy at any time
          and you should check this Privacy Policy periodically. If I make any
          material changes to this Privacy Policy, I will notify you either
          through the email address you have provided me or by placing a
          prominent notice on my website.
        </p>
        <p>
          <strong>Contact Information</strong>
        </p>
        <p>
          For any questions or concerns regarding the privacy policy, please
          send me an email at <a href="mailto:xo-pro@naem.me">xo-pro@naem.me</a>
          .
        </p>
        <p></p>
        <br />
      </article>
    </div>
  );
};

export const Terms = () => {
  useEffect(() => {
    document.title = "Terms";
  }, []);
  return (
    <div>
      <main>
        <div class="container mx-auto max-w-screen-lg px-4 md:px-8 mt-32">
          <div class="container mx-auto my-20 max-w-screen-lg px-6 md:my-24 md:px-8">
            <h2 class="text-center text-1xl font-semibold md:text-4xl">
              XO Pro Terms & Conditions
            </h2>
          </div>

          <hr />
          <article class="my-5 md:my-24">
            <div class="prose max-w-none md:prose-lg lg:prose-xl">
              <p>
                <strong>Introduction</strong>
              </p>
              <p>
                Welcome to XO Pro, a product of Ahmad Alsobhi ( &quot;we&quot;,
                &quot;our&quot;, &quot;us&quot;)! By using our App, you agree to
                these Terms & Conditions (the &quot;Terms&quot;).
              </p>
              <p>
                <strong>Acceptance of Terms</strong>
              </p>
              <p>
                By accessing and using XO Pro, you accept and agree to be bound
                by these Terms. If you disagree with any part of the Terms, you
                may not access the App.
              </p>
              <p>
                <strong>User&#x27;s Responsibilities</strong>
              </p>
              <p>
                You are responsible for the content that you create via Tafseer
                AI. You must ensure the content adheres and complies with all
                applicable local, state, national, or international laws.
              </p>
              <p>
                <strong>Privacy Policy</strong>
              </p>
              <p>
                Please refer to our Privacy Policy for information on how we
                collect, use, and disclose the information we collect from you.
              </p>
              <p>
                <strong>Subscriptions and in-app purchases</strong>
              </p>
              <p>
                We offer several options for in-app purchases and subscriptions,
                including weekly,monthly,and yearly subscription. The user must
                have a valid ID account for financial procedures to be able to
                obtain the purchase service.
              </p>
              <p>
                <strong>Auto Renew</strong>
              </p>
              <p>
                Subscriptions are currently renewing. The amount required for
                renewal is automatically collected by receiving it.
              </p>
              <p>
                <strong>Prices</strong>
              </p>
              <p>
                Prices for products and in-app purchases vary according to the
                required options and features. Information about pricing can be
                found within the app before purchasing.
              </p>
              <p>
                <strong>Refund</strong>
              </p>
              <p>
                Refund your money after returning a refund request to the Apple
                Store.
              </p>
              <p>
                <strong>Cancellation Policy</strong>
              </p>
              <p>
                If you wish to cancel your subscription to our services, you
                must follow the respective cancellation procedures specified by
                Apple and Google.
              </p>
              <p>
                <strong>Copyright and Intellectual Property</strong>
              </p>
              <p>
                The App, including its graphics, logos, designs, text, sounds,
                and all other data, is owned by Ahmad Alsobhi or its licensors
                and is protected by copyright laws.
              </p>
              <p>
                <strong>Grant of License</strong>
              </p>
              <p>
                XO Pro grants you a personal, non-exclusive, non-transferable
                license to use the App on any device that you own or control.
              </p>
              <p>
                <strong>Termination</strong>
              </p>
              <p>
                We reserve the right to terminate your access to our App without
                any prior notice for violation of these Terms.
              </p>
              <p>
                <strong>Governing Law</strong>
              </p>
              <p>
                These Terms and any dispute concerning, relating, or referring
                to the Terms shall be governed by the laws of the country where
                us is registered.
              </p>
              <p>
                <strong>Changes to the Terms & Conditions</strong>
              </p>
              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time.
              </p>
              <p>
                <strong>Contact Information</strong>
              </p>
              <p>
                If you have any questions or concerns regarding these Terms of
                Service, please contact us at
                <a href="mailto:xo-pro@naem.me"> xo-pro@naem.me </a>.
              </p>
            </div>
          </article>
        </div>
      </main>
    </div>
  );
};

export const Support = () => {
  useEffect(() => {
    document.title = "Support";
  }, []);
  return (
    <div>
      <main>
        <div class="container mx-auto max-w-screen-lg px-4 md:px-8 mt-32">
          <div class="container mx-auto my-16 max-w-screen-lg px-6 md:my-24  md:px-8">
            <h2 class="text-center text-1xl font-semibold md:text-4xl">
              XO Pro Support
            </h2>
          </div>

          <hr />
          <article class="my-5 md:my-24">
            <p className="">
              You can contact us at
              <a href="mailto:xo-pro@naem.me"> xo-pro@naem.me </a> if you need
              support with XO Pro.
            </p>
          </article>
        </div>
      </main>
    </div>
  );
};
